export function santeActifRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        console.log(pr.produit.produit_ws_nom)
        switch(pr.produit.produit_ws_nom){
            case 'MALIN':
            case 'SMART':
            case 'INSURACTIF_2024':
                produitsResult.push(santeActifProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function santeActifProduit(produit,garantie,exigences){
    // ------------------- INSURACTIF -------------------
    let FORMULE_1 ={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let FORMULE_2={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let FORMULE_3={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let FORMULE_4={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let FORMULE_5={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    // ------------------- SPV MALIN -------------------
    let Formule_1={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_2={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_3={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }

    //------------------- SPV SMART
    let Formule_A_ESSENTIELLE={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_A_CONFORT={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_B_ESSENTIELLE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_B_CONFORT={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Economique'
    }
    let Formule_C_ESSENTIELLE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_C_CONFORT={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_C_FIDELITE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_D_ESSENTIELLE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Complète'
    }
    let Formule_D_CONFORT={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_D_FIDELITE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_E_CONFORT={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_E_FIDELITE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_F_CONFORT={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    let Formule_F_FIDELITE={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences),
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences),
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences),
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences),
        },
        grNiveau:'Haut de gamme'
    }
    // ------------------- Treatment -------------------

    switch(produit.tarif.formule){
        // ------------------- INSURACTIF -------------------
        case 'FORMULE_1':
            calcultauxtarif(FORMULE_1,garantie,produit)
        break;
        case 'FORMULE_2':
            calcultauxtarif(FORMULE_2,garantie,produit)
        break;
        case 'FORMULE_3':
            calcultauxtarif(FORMULE_3,garantie,produit)
        break;
        case 'FORMULE_4':
            calcultauxtarif(FORMULE_4,garantie,produit)
        break;
        case 'FORMULE_5':
            calcultauxtarif(FORMULE_5,garantie,produit)
        break;
        // ------------------- Malin -------------------
        case 'Formule 1':
            calcultauxtarif(Formule_1,garantie,produit)
        break;
        case 'Formule 2':
            calcultauxtarif(Formule_2,garantie,produit)
        break;
        case 'Formule 3':
            calcultauxtarif(Formule_3,garantie,produit)
        break;
        // ---------------------SPV SMART---------------
        case 'AE':
            calcultauxtarif(Formule_A_ESSENTIELLE,garantie,produit)
            break;
        case 'AC':
            calcultauxtarif(Formule_A_CONFORT,garantie,produit)
            break;
        case 'BE':
            calcultauxtarif(Formule_B_ESSENTIELLE,garantie,produit)
            break;
        case 'BC':
            calcultauxtarif(Formule_B_CONFORT,garantie,produit)
            break;
        case 'CE':
            calcultauxtarif(Formule_C_ESSENTIELLE,garantie,produit)
            break;
        case 'CC':
            calcultauxtarif(Formule_C_CONFORT,garantie,produit)
            break;
        case 'CF':
            calcultauxtarif(Formule_C_FIDELITE,garantie,produit)
            break;
        case 'DE':
            calcultauxtarif(Formule_D_ESSENTIELLE,garantie,produit)
            break;
        case 'DC':
            calcultauxtarif(Formule_D_CONFORT,garantie,produit)
            break;
        case 'DF':
            calcultauxtarif(Formule_D_FIDELITE,garantie,produit)
            break;
        case 'EC':
            calcultauxtarif(Formule_E_CONFORT,garantie,produit)
            break;
        case 'EF':
            calcultauxtarif(Formule_E_FIDELITE,garantie,produit)
            break;
        case 'FC':
            calcultauxtarif(Formule_F_CONFORT,garantie,produit)
            break;
        case 'FF':
            calcultauxtarif(Formule_F_FIDELITE,garantie,produit)
            break;
        
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function calcultauxtarif(pgniveau,garantie,produit){
    let total=0
    let cpt=0
    Object.entries(pgniveau).forEach(([key, o]) => {
        if(key!=='grNiveau'){
            let somme=0;
            if(o.value==o.choose){
                somme=1
            }
            total+=somme;
            cpt++
        }
    });
    produit.tarif.tauxGn  = pgniveau.grNiveau==garantie ? 1 : 0
    produit.tarif.tauxCouv= total/cpt
}

function searchoption(option,exigences){
    let tmpOption= exigences.find( pg => pg.label === option);
    return tmpOption ? mapAndGetResult(tmpOption) : 1
}

function mapAndGetResult(exigence){
    let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
    if(tmpEx && tmpEx.exigence_niveau_libel){
        switch(tmpEx.exigence_niveau_libel.toUpperCase()){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    }else{
        return 1;
    }
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}
